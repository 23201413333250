import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddProspectForm from "./AddProspectForm";
import { Delete, Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import ArrowUpwardIcon from "@mui/icons-material/ExpandLess";
import ArrowDownwardIcon from "@mui/icons-material/ExpandMore";
import ImportProspects from "./ImportProspects";

const Prospects = (props) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  console.log("user at propsect", user);
  const [prospects, setProspects] = useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const [showImportForm, setShowImportForm] = useState(false);
  const [organizationInformation, setOrganizationInformation] = useState({});

  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  console.log("userSubscriptionData", userSubscriptionData);

  const [noOfLeadsAllowed, setNoOfLeadsAllowed] = useState("");
  useEffect(() => {
    console.log("user subscription data at pros[pects", userSubscriptionData);
    // if (!userSubscriptionData?.trial_done && userSubscriptionData?.ontrial) {
    //   setNoOfLeadsAllowed(100000000);
    // } else if (userSubscriptionData?.account_type == "free") {
    //   setNoOfLeadsAllowed(10);
    // } else {
    setNoOfLeadsAllowed(100000000);
    // }
  }, [userSubscriptionData]);

  useEffect(() => {
    api
      .get("/organization")
      .then((res) => {
        console.log("organization information", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    api
      .get("/prospects")
      .then((res) => {
        console.log("response from getting prospects", res?.data);
        setProspects(res?.data);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm, showImportForm]);

  /****************************** */
  const [sorting, setSorting] = useState({
    field: "lead_serial_number", // default sorting field
    order: "asc", // default sorting order
  });

  const handleSort = (field) => {
    const isAsc = sorting.field === field && sorting.order === "asc";
    setSorting({ field, order: isAsc ? "desc" : "asc" });
  };

  const sortedData = prospects.sort((a, b) => {
    const order = sorting.order === "asc" ? 1 : -1;
    if (a[sorting.field] < b[sorting.field]) {
      return -1 * order;
    }
    if (a[sorting.field] > b[sorting.field]) {
      return 1 * order;
    }
    return 0;
  });

  return (
    <Box sx={{ padding: "0" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          {" "}
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.heading.main,
              fontSize: "1.3rem",
              fontWeight: "600",
            }}
          >
            {"Leads"}
          </Typography>
          <Typography>
            You can add{" "}
            {/* {userSubscriptionData?.account_type == "free" &&
            !(
              userSubscriptionData?.ontrial && !userSubscriptionData?.trial_done
            )
              ? `maximum ${noOfLeadsAllowed} `
              : "infinite"}{" "} */}
            infinite leads in this organization
          </Typography>
        </Box>

        <Box>
          {organizationInformation?.id && (
            <>
              {
                // userSubscriptionData?.account_type == "premium" ||
                // (userSubscriptionData?.ontrial &&
                //   !userSubscriptionData?.trial_done) ||
                // prospects?.length < 10
                true ? (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      onClick={() => setShowImportForm(!showImportForm)}
                      sx={{
                        backgroundColor: theme.palette.colortwo.main,
                        color: "#fff",
                        mr: 2,
                      }}
                      size="small"
                    >
                      {showImportForm ? "View Prospects" : "Import"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowAddForm(!showAddForm);
                        setShowImportForm(false);
                      }}
                      sx={{
                        backgroundColor: theme.palette.colortwo.main,
                        color: "#fff",
                      }}
                      size="small"
                    >
                      {showAddForm ? "View Prospects" : "Create new"}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button disabled>Maximum limit reached</Button>
                  </>
                )
              }
            </>
          )}
        </Box>
      </Box>

      {!organizationInformation?.id ? (
        <Box>
          <Typography sx={{ mt: 3 }}>
            {" "}
            Update your organization details
          </Typography>
        </Box>
      ) : showImportForm ? (
        <ImportProspects
          setShowAddForm={setShowAddForm}
          organizationInformation={organizationInformation}
          prospects={prospects}
          setShowImportForm={setShowImportForm}
        />
      ) : showAddForm ? (
        <AddProspectForm
          editOptions={editOptions}
          setShowAddForm={setShowAddForm}
          organizationInformation={organizationInformation}
          prospects={prospects}
        />
      ) : prospects?.length < 1 ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: "10px" }}>
          <InfoIcon />
          <Typography sx={{}}> No lead is added yet.</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ marginTop: "20px" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "70vh", overflowY: "scroll" }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "80px",
                            whiteSpace: "nowrap",
                            color: theme.palette.colortwo.main,
                          }}
                        >
                          {" "}
                          <Box
                            sx={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "-2px",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "lead_serial_number",
                                    order: "asc",
                                  })
                                }
                              >
                                <ArrowUpwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "lead_serial_number",
                                    order: "desc",
                                  })
                                }
                              >
                                <ArrowDownwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                            </Box>
                            Sr. No
                          </Box>
                        </TableCell>
                        {/* <TableCell>Customer ID</TableCell> */}
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Organization Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Contact person
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "-2px",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "value_of_lead",
                                    order: "asc",
                                  })
                                }
                              >
                                <ArrowUpwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "value_of_lead",
                                    order: "desc",
                                  })
                                }
                              >
                                <ArrowDownwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                            </Box>
                            Value of lead
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "-2px",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "lead_serial_number",
                                    order: "asc",
                                  })
                                }
                              >
                                <ArrowUpwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setSorting({
                                    field: "lead_serial_number",
                                    order: "desc",
                                  })
                                }
                              >
                                <ArrowDownwardIcon
                                  size="small"
                                  sx={{ fontSize: "0.85rem" }}
                                />
                              </IconButton>
                            </Box>
                            Date
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Industry
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Service Needed
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Lead Source
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          City
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Country
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Primary Phone
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Other Phone
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Owner
                        </TableCell>

                        {/* {user?.profile_type == "admin" && ( */}
                        <TableCell
                          sx={{
                            color: theme.palette.colortwo.main,
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Actions
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData?.map((contact, i) => (
                        <NormalProspect
                          contact={contact}
                          i={i}
                          prospects={prospects}
                          setProspects={setProspects}
                          setShowAddForm={setShowAddForm}
                          setEditOptions={setEditOptions}
                        />
                      ))}{" "}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

const NormalProspect = ({
  contact,
  i,
  prospects,
  setProspects,
  setEditOptions,
  setShowAddForm,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [owner, setOwner] = useState({});

  const deleteCustomer = () => {
    console.log("contact id", contact?.lead_serial_number);
    api
      .post("/deleteProspect", contact)
      .then((res) => {
        console.log("delete user response", res?.data);
        toast.success("Deleted Successfully!");
        setProspects(
          prospects?.filter(
            (c) => c?.lead_serial_number !== contact?.lead_serial_number
          )
        );
      })
      .catch((err) => {
        console.log("error in deleting user", err);
        toast.error("unable to delete!");
      });
  };
  useEffect(() => {
    if (contact?.owner_id) {
      api
        .get("/getUserById/" + contact?.owner_id)
        .then((res) => {
          console.log("getting owner", res?.data);
          setOwner(res?.data);
        })
        .catch((err) => console.log("err", err));
    }
  }, []);
  const editSupplier = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };

  if (
    user?.profile_type != "admin" &&
    user?.id != contact?.owner_id &&
    !user?.authorized_to_company_details
  )
    return <></>;
  return (
    <TableRow
      key={contact?.lead_serial_number}
      style={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
      }}
    >
      <TableCell
        sx={{ width: "50px", whiteSpace: "nowrap", padding: "20px 20px" }}
      >
        {contact?.organizational_identifier}
      </TableCell>
      {/* <TableCell>{contact?.customer_id}</TableCell> */}
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.organization_name}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.contact_person}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.value_of_lead}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {new Date(contact?.date)?.toDateString()}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.email}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.industry}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.service_needed}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.lead_source}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.city}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.country}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.primary_phone_number}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {contact?.other_phone_number ? contact?.other_phone_number : "N/A"}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "20px 20px" }}>
        {owner?.name ? owner?.name : ""}
      </TableCell>

      <TableCell>
        {user?.profile_type == "admin" || user?.id == contact?.owner_id ? (
          // ||
          // user?.authorized_to_company_details
          <Box
            sx={{ display: "flex", cursor: "pointer" }}
            onClick={() => editSupplier()}
          >
            {/* <IconButton onClick={() => editSupplier()}> */}
            <Edit
              size="16"
              color={theme.palette.colortwo.main}
              sx={{ cursor: "pointer" }}
            />
            {/* </IconButton> */}
            {/* <IconButton onClick={() => deleteCustomer()}>
              <Delete size="18" color={theme.palette.colortwo.main} />
            </IconButton> */}
          </Box>
        ) : (
          "restricted"
        )}
      </TableCell>
    </TableRow>
  );
};

export default Prospects;
